import { reactive } from "vue";

export const searchState = reactive({
    active: false,

    getIsActive() {
        return this.active;
    },

    setIsActive(isActive) {
        this.active = isActive;
        return this;
    },
});
